import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "categories","result", "plot", "x", "y", "xvalue", "yvalue", "xtext", "ytext", "xhint", "yhint", "dot" ]

  connect() {
    let _this = this;
    const chipElements = this.element.querySelectorAll('.categories .mdc-chip');
  
    //console.log(" -> Free form connected")
    if (_this.resultTarget.innerHTML.length == 0) {
      _this.resultTarget.innerHTML = '{"x":50.0,"y":50.0}';
    }


    document.addEventListener("MDCAutoInit:End", () => {
      _this.initPlot(_this.plotTarget);
      _this.initSliders(_this.resultTarget);

      let slider_x = _this.xTarget.MDCSlider;
      let slider_y = _this.yTarget.MDCSlider;

      _this.dotTarget.addEventListener("click", function(event) {
        _this.submitXYResult(slider_x.value, slider_y.value);
        _this.initPlot(_this.plotTarget)
      });

      slider_x.listen('MDCSlider:change', () => {
        _this.submitXYResult(slider_x.value, slider_y.value);
        _this.initPlot(_this.plotTarget)
      });

      slider_y.listen('MDCSlider:change', () => {
        _this.submitXYResult(slider_x.value, slider_y.value);
        _this.initPlot(_this.plotTarget)
      });

    });

    _this.plotTarget.addEventListener("click", function(event) {
      _this.clicked(event);
      _this.initPlot(_this.plotTarget)
      _this.initSliders(_this.resultTarget);
    });

    chipElements.forEach((chipEl) => {   
      const chip = new MDCChip(chipEl);   
      chip.listen('MDCChip:interaction', () => {

        if (($("#form_categories").children().length < 5) || chip.selected) {
          console.log($("#form_categories").children().length);
          chip.selected = !chip.selected;
          setTimeout(function () {_this.setCategory($(chipEl).data("category"));}, 200);
        }
      });  
    });
  }

  setCategory(category) {

    $("#form_categories").empty();
    $(".categories .mdc-ripple-upgraded--foreground-activation:not(.mdc-chip--selected), .categories .mdc-chip--selected:not(.mdc-ripple-upgraded--foreground-activation)").each(function( index ) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = "free_form_request["+$( this ).data("fieldname")+"][]";
      input.value = $( this ).data("category");

      $("#form_categories").append(input);
    });

  }

  submitXYResult(x, y) {
    var res = {"x": x, "y": y};

    this.resultTarget.value = JSON.stringify(res);

    //VALIDATION
    this.resultTarget.dispatchEvent(new Event('input'));

    if (this.resultTarget.closest('form') != null)
      this.resultTarget.closest('form').dispatchEvent(new Event('input'));

    this.resultTarget.setCustomValidity('');
    
    this.setXYSliderValue(x,y);
  }

  clicked(event) {
    var xpr = ((event.clientX - this.plotTarget.getBoundingClientRect().left) / this.plotTarget.getBoundingClientRect().width) * 100;
    var ypr = ((event.clientY - this.plotTarget.getBoundingClientRect().top) / this.plotTarget.getBoundingClientRect().height) * 100;

    this.submitXYResult(parseFloat(xpr.toFixed(1)), 100 - parseFloat(ypr.toFixed(1)))
    this.initSliders(this.resultTarget);

  }

  pointParser(target) {
    var point = target.value;
    var x, y = [0, 0];

    if (point.length > 0) {
      var point_json = JSON.parse(point),
          x = point_json.x,
          y = point_json.y;
    }

    return [x, y];
  }

  initSliders(target) {
    var [x,y] = this.pointParser(target);
      
    
    this.xTarget.MDCSlider.value = Math.round(x);
    this.yTarget.MDCSlider.value = Math.round(y);

    this.setXYSliderValue(x,y);
    
  }

  initPlot(target) {

    var point = this.resultTarget.value;
    let map = target.closest(".map2d_tool");

    if (point.length > 0) {
      var point_json = JSON.parse(point),
          x = point_json.x,
          y = point_json.y,
          width = map.querySelector(".map2d").getBoundingClientRect().width;
      
      var dot_css = `left: ${x}%; top: ${100 - y}%;`;
      
      map.querySelector(".dot").style.cssText = dot_css;
    }
    
    var clip_css = `width: ${x}%; height: ${y}%;`;
    map.querySelector(".clip").style.cssText += clip_css; 

    map.querySelectorAll(".label").forEach((el) => {
      el.classList.remove('font-600');
    });
    let label_x = 1;
    let label_y = 1;

    label_x = Math.trunc(Math.round(x)/20);
    label_y = Math.trunc(Math.round(y)/20);

    if (x >= 90) 
      label_x = 5
    else if (x >= 70)
      label_x = 4
    else if (x >= 50)
      label_x = 3
    else if (x >= 30)
      label_x = 2
    else 
      label_x = 1

    if (y >= 90) 
      label_y = 5
    else if (y >= 70)
      label_y = 4
    else if (y >= 50)
      label_y = 3
    else if (y >= 30)
      label_y = 2
    else 
      label_y = 1
    map.querySelector(`.label.x${label_x}`).classList.add('font-600');
    map.querySelector(`.label.y${label_y}`).classList.add('font-600');

  }

  setXYSliderValue(x,y) {

    if (x != undefined) {
      let text = "",
          hint = "";

      x = Math.round(x);

      if (x >= 90) {
        text = "Exceptional";
        hint = "The innovation could be easily adopted and adapted in contexts I'm familiar with to an excellent standard and sustained for the foreseeable future.";
      } else if (x >= 70) {
        text = "High";
        hint = "As per “Exceptional,” except only achieved for part(s) of the innovation.";
      } else if (x >= 50) {
        text = "Moderate";
        hint = "The innovation could be adopted and adapted in contexts I'm familiar with to a high standard and sustained for the foreseeable future.";
      } else if (x >= 30) {
        text = "Limited";
        hint = "As per “Insufficient,” however, the innovation has some aspects which are at the “Moderate” level.";
      } else {
        text = "Insufficient";
        hint = "The innovation could not be easily and sustainably adopted and adapted with similar quality standards in contexts I'm familiar with.";
      }

      this.xvalueTarget.innerHTML = x;
      this.xtextTarget.innerHTML = text;
      this.xhintTarget.innerHTML = hint;
    }

    if (y != undefined) {
      let text = "",
          hint = "";
      y = Math.round(y);

      if (y >= 90) {
        text = "Exceptional";
        hint = "The innovation could have a significant and meaningful impact in contexts I'm familiar with to an excellent standard above what commonly occurs.";
      } else if (y >= 70) {
        text = "High";
        hint = "As per “Exceptional,” except only achieved for part(s) of the innovation.";
      } else if (y >= 50) {
        text = "Moderate";
        hint = "The innovation could have a meaningful impact in contexts I'm familiar with to a standard above what commonly occurs.";
      } else if (y >= 30) {
        text = "Limited";
        hint = "As per “Insufficient,” however, the innovation has some aspects which are at the “Moderate” level.";
      } else {
        text = "Insufficient";
        hint = "The innovation would not have a meaningful impact in contexts I'm familiar with to a standard above what commonly occurs.";
      }

      this.yvalueTarget.innerHTML = y;
      this.ytextTarget.innerHTML = text;
      this.yhintTarget.innerHTML = hint;
    }

  }

}
