import { Controller } from "stimulus"
import Rails from '@rails/ujs';
import {MDCSelect} from '@material/select';

export default class extends Controller {
  static targets = [ ]

  initialize() {
    let _this = this;
    window.mdcAutoInit();
    document.addEventListener("MDCAutoInit:End", () => {_this.initSelects();});
  }

  initSelects() {
    let _this = this;

    document.querySelectorAll(".mdc-select").forEach(element => {
      if (element.getAttribute('listener4Update') !== 'true') {
        let select = element.MDCSelect;

        let initSelectUpdate = function(e) {
          _this.updateParticipation(element.dataset.id, select.value);
        }
        //select.unlisten('MDCSelect:change');
        select.listen('MDCSelect:change', initSelectUpdate);
        //console.log("listener4Update", "ADDED");
        element.setAttribute('listener4Update', 'true');
      }
    });
  }

  updateParticipation(id, value) {
    let _this = this;

    let data = {"participation": {"status": value}};

    fetch('/participations/' + id + '.js', {
      method: 'PUT',
      mode: 'same-origin', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.text())
    .then((data) => {
      document.querySelector("#partiaipation"+id).outerHTML = data;

      window.mdcAutoInit();

      document.addEventListener("MDCAutoInit:End", () => {_this.initSelects();});
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

} 
