import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "editor" ]

  initialize() {
    this.initTrumbowyg();
  }

  initTrumbowyg(event) {
    let _this = this;
    var root_path = window.location.protocol + '//' + window.location.host;

    var options = {
        btnsDef: {
            image: {
                dropdown: ['insertImage'],
                ico: 'insertImage'
            },
            orderlist: {
              dropdown: ['unorderedList', 'orderedList'],
              ico: 'orderedList'
            },
            sed: {
              dropdown: ['strong', 'em', 'del'],
              ico: 'strong'
            }
        },
        btns: [
          ['undo', 'redo'],
          ['sed'],
          ['link'],
          ['image'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['orderlist'],
          ['removeformat']
        ],
        autogrow: true,
        removeformatPasted: true,
        urlProtocol: true,
        minimalLinks: true,
        imageWidthModalEdit: true,
        svgPath: "/assets/ui/icons-2af4ee240de260f43f147c21ebc37880a1d0bf8e5c855e1defc9b8e6293a1d7a.svg",
        plugins: {
          table: {
            rows: 5,
            columns: 7
          },
          upload: {
            serverPath:  root_path + "/images",
            fileFieldName: 'image[file]',
            urlPropertyName: 'data.link',
            success: function(data, trumbowyg, modal) {
              var imageIdHtml, url;
              if (data.st === 'success') {
                url = data.url;
                trumbowyg.execCmd('insertImage', url);
                imageIdHtml = '<input type="hidden" name="campaign[image_id][]" value="' + data.image_id + '">';
                $('.newMessage').append(imageIdHtml);
                setTimeout((function() {
                  trumbowyg.closeModal();
                }), 250);
              } else {
                trumbowyg.addErrorOnModalField($('input[type=file]', modal), trumbowyg.lang.uploadError || data.message);
              }
            }
          }
        }
    };

    $("#" + _this.editorTarget.id).trumbowyg(options);
  }

}
