import { Controller } from "stimulus"
import QRCode from 'qrcode'

export default class extends Controller {
  static targets = [ "qrcanva" ]

  connect() {
    let _this = this;
      
    var opts = {  
      errorCorrectionLevel: 'M',
      quality: 1,
      margin: 2,
      width: 250,
      color: {
        dark:"#c32fa0",
        light:"#F1F1F1"
      }
    }

    QRCode.toCanvas(_this.qrcanvaTarget, _this.data.get("url"), opts,function (error) {
      // if (error) console.error(error)
      //   console.log('success!');
    })
  }

}
