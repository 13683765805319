//https://docs.amcharts.com/3/javascriptmaps/MapObject#mouseEnabled

var amap = null,
    state = 'basicDataProvider';
var ungropped_new_ds = [];
var new_ds = [];
var homeButtonClicked = false;
var current_row_index = 0;
var currentMapObject = undefined;
var g_country = null;

var locations = [];

var current_group_item = 0;

function initNewUngroppedDots (locations_reference) {
  
  var ll = JSON.parse(JSON.stringify(locations_reference));

  var location = null,
      front_dots = [],
      base_dots = [],
      used_indexes = [];
    
    for(var y = 0, yLen = ll.length; y < yLen; y++) {
      if (used_indexes.indexOf(y) > -1)
        continue

      var dot = new AmCharts.MapObject();
      
      location = ll[y];

      dot.title = "";
      //DOTS MODIFICATIONS
      dot.width = 12;
      dot.height = 12;
      dot.bringForwardOnHover = true;
      dot.fixedSize = true;
      dot.longitude = location.lon;
      dot.latitude = location.lat;
      dot.remainVisible = false;
      dot.descriptionWindowWidth = 0;

      if (location["type"] == 'user')
        dot.color = "#000000";
      else
        dot.color = "#c32fa0";

      dot.id = location["id"];
      dot.ids = [location["id"]];

      dot.description = "";        

      //LINES
      if (dot.lines == undefined)
        dot.lines = [];

      if (dot.images == undefined)
        dot.images = [];


      dot.type = "circle";
      dot.chart = amap;
      used_indexes.push(y);

      var group_flag = false;

      for(var x = 0, xLen = ll.length; x < xLen; x++) {
        if ((Math.abs(dot.longitude - ll[x].lon) < 0.5) && (Math.abs(dot.latitude - ll[x].lat) < 0.5) && (used_indexes.indexOf(x) == -1)) { 
          dot.width = 24;
          dot.height = 24;
          dot.id = "group"+y; 
          used_indexes.push(x);

          //dot.description += " ";   

          if (dot.ids === undefined) {
            dot.ids = [ll[x]["id"]];
          } else {
              // LAbel https://docs.amcharts.com/3/javascriptmaps/MapImage
              dot.ids.push(ll[x]["id"]);
              dot.label = dot.ids.length;
              dot.labelShiftX = "-10px";
              dot.labelColor = "#ffffff";
              dot.labelRollOverColor = "#ffffff";
              dot.labelFontSize = "12";
              dot.rollOverScale = 1.2;
          }

        }
      }
      
      front_dots.push(dot);
    }

  return front_dots;

}

function initNewGroppedDots (locations_reference, group_level) {
  // var description_window_width = 472;

  // if ( $(window).width() <= 840) {
  //   description_window_width = 272;
  // }
  
  var ll = JSON.parse(JSON.stringify(locations_reference));

  var location = null,
      front_dots = [],
      base_dots = [],
      used_indexes = [];
    
    for(var y = 0, yLen = ll.length; y < yLen; y++) {
      if (used_indexes.indexOf(y) > -1)
        continue

      var dot = new AmCharts.MapObject();
      
      location = ll[y];

      dot.title = "";
      //DOTS MODIFICATIONS
      dot.width = 12;
      dot.height = 12;
      dot.bringForwardOnHover = true;
      dot.fixedSize = true;
      dot.longitude = location.lon;
      dot.latitude = location.lat;
      dot.remainVisible = false;
      dot.descriptionWindowWidth = 0;
      
      if (location["type"] == 'user')
        dot.color = "#000000";
      else
        dot.color = "#c32fa0";


      dot.id = location["id"];
      dot.ids = [location["id"]];

      dot.description = "";  
      

      //LINES
      if (dot.lines == undefined)
        dot.lines = [];

      if (dot.images == undefined)
        dot.images = [];

      dot.type = "circle";
      dot.chart = amap;
      used_indexes.push(y);

      var group_flag = false;

      for(var x = 0, xLen = ll.length; x < xLen; x++) {
        if ((Math.abs(dot.longitude - ll[x].lon) < group_level) && (Math.abs(dot.latitude - ll[x].lat) < group_level) && (used_indexes.indexOf(x) == -1)) { 
          dot.width = 24;
          dot.height = 24;
          dot.id = "group"+y; 
          used_indexes.push(x);

          //dot.description += " ";

          if (dot.ids === undefined) {
            dot.ids = [ll[x]["id"]];
          } else {
              // LAbel https://docs.amcharts.com/3/javascriptmaps/MapImage
              dot.ids.push(ll[x]["id"]);
              dot.label = dot.ids.length;
              dot.labelShiftX = "-10px";
              dot.labelColor = "#ffffff";
              dot.labelRollOverColor = "#ffffff";
              dot.labelFontSize = "12";
              dot.rollOverScale = 1.2;
          }

        }
      }
      
      front_dots.push(dot);
    }

  return front_dots;
}

function newDataProvider () {
  return getDataProvider(g_country, ungropped_new_ds);
}

function basicDataProvider () {
  return getDataProvider(g_country, new_ds);
}

function changeLinesFor(index) {
  var l_locations = JSON.parse(JSON.stringify(locations));
  
  var current_id = currentMapObject.ids[current_row_index];

  for(var x = 0, xLen = l_locations.length; x < xLen; x++) {
    if (l_locations[x]['id'] == current_id) {
      //alert();

      for (var j = currentMapObject.lines.length - 1; j >= 0; j--) {
        currentMapObject.lines[j].deleteObject();
      }

      for (var jj = currentMapObject.images.length - 1; jj >= 0; jj--) {
        currentMapObject.images[jj].deleteObject();
      }

      currentMapObject.lines = [];
      currentMapObject.images = [];

      for (var i = l_locations[x].lines.length - 1; i >= 0; i--) {
        var line = new AmCharts.MapLine();
        line.id = i;
        line.latitudes = l_locations[x].lines[i].latitudes;
        line.longitudes = l_locations[x].lines[i].longitudes;
        line.selectable = false;
        line.chart = amap;
        line.validate();
        currentMapObject.lines.push(line);
      };

      for (var ii = l_locations[x].images.length - 1; ii >= 0; ii--) {
        var image = new AmCharts.MapImage();
        image.id = ii;
        image.latitude = l_locations[x].images[ii].latitude;
        image.longitude = l_locations[x].images[ii].longitude;
        image.type = l_locations[x].images[ii].type;
        image.color = l_locations[x].images[ii].color;
        image.width = l_locations[x].images[ii].width;
        image.height = l_locations[x].images[ii].height;
        image.scale = l_locations[x].images[ii].scale;
        image.fixedSize = l_locations[x].images[ii].fixedSize;
        image.bringForwardOnHover = l_locations[x].images[ii].bringForwardOnHover;
        image.selectable = l_locations[x].images[ii].selectable;
        image.chart = amap;
        image.validate();
        currentMapObject.images.push(image);
      };

      if (l_locations[x].lines.length > 0) {
        var image = new AmCharts.MapImage();
        image.latitude = l_locations[x].lines[0].latitudes[0];
        image.longitude = l_locations[x].lines[0].longitudes[0];
        image.type = "circle";
        image.color = currentMapObject.color;
        image.width = 12;//currentMapObject.width;
        image.height = 12;//currentMapObject.height; 
        image.scale = 1; 
        image.fixedSize = true; 
        image.bringForwardOnHover = true; 
        image.description = currentMapObject.description;
        image.title = "";
        image.chart = amap;
        image.validate();
        currentMapObject.images.push(image);
      }
    }
  }

}

function getDataProvider(country, l_dots) {
  if (country == "finland") {
    return {
      "map": "finlandLow",
      "getAreasFromMap": false,
      "images": l_dots
    }
  } else if (country == "australia") {
    return {
      "map": "australiaHigh",
      "images": l_dots,
      "zoomLevel": 6,
      "zoomLongitude": 145,
      "zoomLatitude": -36.5,
      "areas": [{
        "id": "AU-VIC",
        "selected": false
      }]}
  } else if (country == "pittsburgh") {
    return {
      "map": "usa2High",
      "images": l_dots,
      "zoomLevel": 6,
      "zoomLongitude": -77.6,
      "zoomLatitude": 40.9413608,
      "areas": [{
        "id": "US-PA",
        "selected": false
      }]}
  } else {
    return {
      "map": "worldLow",
      "getAreasFromMap": false,
      "images": l_dots
    }
  }

}

function getAreasSettings(country) {
  if (country == "finland") {
    return {
      "rollOverBrightness": 10,
      "selectedBrightness": 10,
      "selectable": false,
      "unlistedAreasAlpha": 1,
      "unlistedAreasOutlineAlpha": 1,
      "alpha": 1,
      "outlineAlpha": 1,
      "balloonText": "",
      "color": "#DDDDDD",
      "rollOverColor": "#DDDDDD",
      "rollOverOutlineColor": "#DDDDDD"
    }
  } else if (country == "australia") {
    return {
     "rollOverBrightness": 10,
      "selectedBrightness": 10,
      "selectable": false,
      "unlistedAreasAlpha": 0,
      "unlistedAreasOutlineAlpha": 0,
      "alpha": 1,
      "outlineAlpha": 1,
      "balloonText": "",
      "color": "#DDDDDD",
      "rollOverColor": "#DDDDDD",
      "rollOverOutlineColor": "#DDDDDD"
    }
  } else if (country == "pittsburgh") {
    return {
      "rollOverBrightness": 10,
      "selectedBrightness": 10,
      "selectable": false,
      "unlistedAreasAlpha": 0.2,
      "unlistedAreasOutlineAlpha": 0,
      "alpha": 1,
      "outlineAlpha": 1,
      "balloonText": "",
      "color": "#DDDDDD",
      "rollOverColor": "#DDDDDD",
      "rollOverOutlineColor": "#DDDDDD"
    }
  } else {
    return {}
  }
}


export function initBigMap4Innovation(areas) {

  amap = AmCharts.makeChart( "map", {
                                  type: "map",
                                  projection: "eckert3",
                                  panEventsEnabled : true,
                                  backgroundAlpha : 0,
                                  zoomControl: {
                                    zoomControlEnabled : true,
                                    right: 10,
                                    top: 30
                                  },
                                  dataProvider : {
                                    map : "worldLow",
                                    getAreasFromMap : true,
                                    areas : areas
                                  },
                                  areasSettings : {
                                  autoZoom : true,
                                  color : "#DDDDDD",
                                  colorSolid : "#c32fa0",
                                  selectedColor : "#c32fa0",
                                  rollOverColor : "#c32fa0",
                                  rollOverOutlineColor : "#DDDDDD"
                                  }
  }); 
}

export function initBigMap(country, dragMap, preventDragOut, map_projection, zoomControlEnabled, group_level, _locations) {
  locations = _locations;
  g_country = country;

  ungropped_new_ds = initNewUngroppedDots(locations);
  new_ds = initNewGroppedDots(locations, group_level);

  amap = AmCharts.makeChart( "map", {
    "type": "map",
    "dragMap": dragMap,
    "preventDragOut": preventDragOut,
    "projection": map_projection,
    "dataProvider": getDataProvider(country, new_ds),
    "areasSettings": getAreasSettings(country), 
    "linesSettings": {
      "arc": -0.7, // this makes lines curved. Use value from -1 to 1
      "arrow": "middle",
      "color": "#c32fa0",
      "alpha": 1,
      "arrowAlpha": 0,
      "arrowSize": 0,
      "thickness": 2
    },  
    "listeners": [{
    "event": "descriptionClosed",
    "method": function(ev) {

    }
  },{
    "event": "rendered",
    "method": function(ev) {

    }
  }],  
    "zoomControl": {
      "gridHeight": 100,
      "draggerAlpha": 1,
      "gridAlpha": 0.2,
      "right": 10,
      "top": 30,
      "zoomControlEnabled": zoomControlEnabled,
      "homeButtonEnabled": zoomControlEnabled
    },
     "backgroundZoomsToTop": true,
    "linesAboveImages": true

  } ); 


  amap.addListener("homeButtonClicked", function(event) {
    amap.dataProvider = basicDataProvider();
    amap.validateNow();
  });

  amap.addListener("zoomCompleted", function(event) {
    if (amap.getDevInfo().zoomLevel < 3 && amap.getDevInfo().zoomLevel != 1 && state != 'basicDataProvider') {
      var zoom = amap.getDevInfo().zoomLevel;
      var zoomX = amap.getDevInfo().zoomX;
      var zoomY = amap.getDevInfo().zoomY;
      amap.dataProvider = basicDataProvider();
      state = 'basicDataProvider';
      amap.validateNow();
      amap.zoomTo(zoom, zoomX, zoomY, true);
    } else if (amap.getDevInfo().zoomLevel >= 4 && state != 'newDataProvider') {

      var zoom = amap.getDevInfo().zoomLevel;
      var zoomX = amap.getDevInfo().zoomX;
      var zoomY = amap.getDevInfo().zoomY;
      amap.dataProvider = newDataProvider();
      state = 'newDataProvider';
      amap.validateNow();
      amap.zoomTo(zoom, zoomX, zoomY, true);
    }

  });

  amap.addListener("clickMapObject", function(event) {
      // current_row_index = 0
      // current_group_item = 0;
      // currentMapObject = event.mapObject;


      // if ( event.mapObject.id == undefined) {
      //   var zoom = amap.getDevInfo().zoomLevel;
      //   var zoomX = amap.getDevInfo().zoomX;
      //   var zoomY = amap.getDevInfo().zoomY;
      //   amap.dataProvider = basicDataProvider();
      //   state = 'basicDataProvider';
      //   amap.validateNow();
      //   amap.zoomTo(zoom, zoomX, zoomY, true);
      // }

      // if (event.mapObject.ids != undefined)
      //   $.get( "/en/addresses", {"ids": event.mapObject.ids}, function( data ) {
      //     if (event.mapObject.ids.length > 1) {
      //       event.mapObject.description = "<div class='group_nav top'></div><div class='group_nav down'></div>";
      //     } else
      //       event.mapObject.description = " ";

      //     event.mapObject.description += data ;
      //     amap.selectObject(event.mapObject);
      //   });

   });
}