import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "output"]

  initialize() {
    this.initSearch();
  }

  initSearch() {
    let _this = this;

    var list = "",
        tags_list = "",
        root = location.origin;

    let timer;
    const searchInput = document.querySelector('#search_key');

    searchInput.addEventListener("keyup", function (e) { 
      clearTimeout(timer);
      timer = setTimeout(() => {
        startSearch();
      }, 300);
    });

    function startSearch() {
      var key = $("#search_key").val().toLowerCase();
      if (key.length > 1) {
        if ($('#search-results').html().length == 0) {
          $("#search-loader").show();
        }
        getSearchResults(key);
        Packs.application.logs();
      } else {
        $('#search-tags').html("");
        $('#search-results').html("");
        $("#search-loader").hide();
      }
    }

    function getSearchResults(key) {
      var key = $.trim(key)
      $.ajax({
        data: {search: {key: key}},
        dataType:'json',
        type:'get',
        url: root + "/en/autocomplete",
        success : function(data, textStatus, jqXHR) {

                    var current_key = $.trim($("#search_key").val().toLowerCase());

                    if (current_key == key) {
                      list = "";
                      tags_list = "";

                      if (data["innovations"] != null) list = list + data["innovations"];
                      if (data["articles"] != null) list = list + data["articles"];
                      if (data["collections"] != null) list = list + data["collections"];
                      if (data["funders"] != null) list = list + data["funders"];
                      if (data["events"] != null) list = list + data["events"];
                      if (data["reports"] != null) list = list + data["reports"];
                      if (data["categories"] != null) tags_list = data["categories"];

                      if (list.length < 1) list = "Nothing found."

                      $("#search-tags").html(tags_list);
                      $("#search-results").html(list);
                      $("#search-loader").hide();
                    }
                  }
      });
    }

  }

  cleanSearch() {
    $('#search_popup input#search_key').val('').focus();
    $('#search-tags').html("");
    $('#search-results').html("");
    $("#search-loader").hide();
  }

} 
