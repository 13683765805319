import "magnific-popup";
import $ from 'jquery'

window.initMagnificPopup = function () {

  initMagnificPopup();
};

export function initMagnificPopup() {
  $('.popup_post').magnificPopup({
    type: 'ajax',
    ajax: {
      settings: {
        headers: {          
          Accept: "text/plain; charset=utf-8",         
          "Content-Type": "text/plain; charset=utf-8"   
        } 
      }
    },
    gallery: {
      enabled: true
    }  
  });

  $('.popup_session_speaker').magnificPopup({
    type: 'ajax', 
    ajax: {
      settings: {
        headers: {          
          Accept: "text/plain; charset=utf-8",         
          "Content-Type": "text/plain; charset=utf-8"   
        } 
      }
    },
    alignTop: true
  });

  $('.popup_speaker').magnificPopup({
    type: 'ajax',
    ajax: {
      settings: {
        headers: {          
          Accept: "text/plain; charset=utf-8",         
          "Content-Type": "text/plain; charset=utf-8"   
        } 
      }
    },
    alignTop: true,
    gallery: {
      enabled: true
    }  
  });

  $('.popup_link').magnificPopup({
    type: 'ajax',
    ajax: {settings: {dataType: "text"}},
    focus: '.white-popup input',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);

        setTimeout(function() {window.mdcAutoInit();}, 400);
      }
    }
  });

  $('.popup_link.close_confirm').magnificPopup({
    type: 'ajax',
    ajax: {
      settings: {
        headers: {          
          Accept: "text/plain; charset=utf-8",         
          "Content-Type": "text/plain; charset=utf-8"   
        } 
      }
    },
    focus: '.white-popup input',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);
        $.magnificPopup.instance.close = function() {
          if ($.magnificPopup.instance.content != null && $.magnificPopup.instance.content != undefined && $.magnificPopup.instance.content.length > 0 && $.magnificPopup.instance.content.hasClass("close_with_confirmation")) {
            var conf_text = $.magnificPopup.instance.content.find(".magnific-close-confirm").html();
            if (conf_text !== undefined) {
              var confirmed = confirm(conf_text);
            }
            
            if(!confirmed) {
              return;
            } else
              location.reload();
            
          } 
          $.magnificPopup.proto.close.call(this);
        };
        //setTimeout(function() {window.mdcAutoInit();}, 400);
      }
    }
  });

  $('.inline_popup').magnificPopup({
    type: 'inline',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);
        //setTimeout(function() {window.mdcAutoInit();}, 400);
      }
    }
  });


  $('.inline_popup.close_confirm').magnificPopup({
    type: 'inline',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);
        $.magnificPopup.instance.close = function() {

          if ($.magnificPopup.instance.content.hasClass("close_with_confirmation")) {
            var conf_text = $.magnificPopup.instance.content.find(".magnific-close-confirm").html();
            if (conf_text !== undefined) {
              var confirmed = confirm(conf_text);
            }
            
            if(!confirmed) {
              return;
            } else
              if (!$.magnificPopup.instance.st.el.hasClass("noreload"))
                location.reload();
            
          } 

          $.magnificPopup.proto.close.call(this);
        };

      }
    }
  });

  $('.iframe_popup').magnificPopup({
      type: 'iframe',
      alignTop: true,
      iframe: {
        patterns: {
            youtube: {
                index: 'youtube.com/', 
                id: function(url) {    
                    var p = "";

                    var m = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                    m = (undefined !== m[2]?m[2].split(/[^0-9a-z_\-]/i)[0]:null);
                    //console.log(m);

                    if ( !m ) return null;
                    p = m + "?autoplay=1";

                    var t = url.match(/[\\?\\&]t=([^\\?\\&]+)/);
                    if ( t && t[1] )  {
                      p = p + "&start=" + t[1]
                    }

                    return p;
                },
                src: '//www.youtube.com/embed/%id%'
            },
            vimeo: {
                index: 'vimeo.com/', 
                id: function(url) {        
                    var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
                    if ( !m || !m[5] ) return null;
                    return m[5];
                },
                src: '//player.vimeo.com/video/%id%?autoplay=1'
            },
            yt: {
                index: 'youtu.be/', 
                id: function(url) {    
                    var p = "";

                    var m = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                    m = (undefined !== m[2]?m[2].split(/[^0-9a-z_\-]/i)[0]:null);
                    //console.log(m);

                    if ( !m ) return null;
                    p = m + "?autoplay=1";

                    var t = url.match(/[\\?\\&]t=([^\\?\\&]+)/);
                    if ( t && t[1] )  {
                      p = p + "&start=" + t[1]
                    }

                    return p;
                },
                src: '//www.youtube.com/embed/%id%'
            },
        }
    }
  });

  
  $('.image_popup').magnificPopup({
    type: 'image',
    alignTop: true,
    gallery: {
      enabled: true
    }  
  });

}
