
$(document).on("click", ".carousel .rounded_arrow.right", function () {
  if ($(this).parent().find(".item.active").last().attr("id") == $(this).parent().find(".item").last().attr("id")) {
    //load more
  } else {
    $(this).parent().find(".item.active").last().next().addClass("active");
    $(this).parent().find(".item.active").first().removeClass("active");
  }
});
    
$(document).on("click", ".carousel .rounded_arrow.left", function () {
  if ($(this).parent().find(".item.active").first().attr("id") != $(this).parent().find(".item").first().attr("id")) {
    $(this).parent().find(".item.active").first().prev().addClass("active");
    $(this).parent().find(".item.active").last().removeClass("active");
  }
});

$(document).on('ready', function() {
  
  if ($(".carousel").length > 0) {
    resizeCarousel();
  }

});

window.onresize = function(event) {
  if ($(".carousel").length > 0) {
    resizeCarousel();
  }
};

function resizeCarousel() {
  $.each($(".carousel"), function () {
    if($(this).width() >= 1140) {
      if ($(this).hasClass("4items") == true) {
        $(this).find(".item.active").first().next().addClass("active");
        $(this).find(".item.active").first().next().next().addClass("active");
        $(this).find(".item.active").first().next().next().next().addClass("active");
      } else {
        $(this).find(".item.active").first().next().addClass("active");
        $(this).find(".item.active").first().next().next().addClass("active");
      }
    } else if ($(this).width() >= 760) {
      if ($(this).hasClass("4items") == true) {
        $(this).find(".item.active").first().next().addClass("active");
        $(this).find(".item.active").first().next().next().removeClass("active");
        $(this).find(".item.active").first().next().next().next().removeClass("active");
      } else {
        $(this).find(".item.active").first().next().addClass("active");
        $(this).find(".item.active").first().next().next().removeClass("active");
      }
    } else if ($(this).width() >= 570) {
      if ($(this).hasClass("4items") == true) {
        $(this).find(".item.active").first().next().addClass("active");
        $(this).find(".item.active").first().next().next().removeClass("active");
        $(this).find(".item.active").first().next().next().next().removeClass("active");
      } else {
        $(this).find(".item.active").first().next().removeClass("active");
        $(this).find(".item.active").first().next().next().removeClass("active");
      }
    } else {
      if ($(this).hasClass("4items") == true) {
        $(this).find(".item.active").first().next().removeClass("active");
        $(this).find(".item.active").first().next().next().removeClass("active");
        $(this).find(".item.active").first().next().next().next().removeClass("active");
      } else {
        $(this).find(".item.active").first().next().removeClass("active");
        $(this).find(".item.active").first().next().next().removeClass("active");
      }
    }
  });

  
}
