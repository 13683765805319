import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "menu", "key", "itemId", "submit" ]

  initialize() {
    window.addEventListener('click', (e) => {
      if (!this.menuTarget.contains(e.target)){
        this.menuTarget.classList.remove('mdc-menu-surface--open');
      }
    });

  }

  listItem(data, type) {

    if (type == "countries") {
      return `<li class="mdc-list-item" data-value="${data.id}" data-name="${data.name}" data-action="click->autocomplete#setItem"><span class="mdc-list-item__ripple"></span><div class="mdc-list-item__text">${data.name}</div></li>`;
    } else if (type == "innovations") {
      return `<li class="mdc-list-item" data-value="${data.root_id}" data-name="${data.name}" data-action="click->autocomplete#setItem"><span class="mdc-list-item__ripple"></span><div class="mdc-list-item__text">${data.name}</div></li>`;
    } else {
      return ``;
    }

  }

  setItem(event) {
    if (this.hasItemIdTarget) {
      this.itemIdTarget.value = event.currentTarget.dataset.value;
    }

    this.keyTarget.value = event.currentTarget.dataset.name;
    this.menuTarget.querySelector('.mdc-list').innerHTML = "";
    this.menuTarget.classList.remove('mdc-menu-surface--open');
    this.submitTarget.disabled = false;
  }

  getObjects(event) {
    let _this = this;

    let key = this.keyTarget.value;
    
    if (this.hasItemIdTarget) {
      this.itemIdTarget.value = null;
      this.submitTarget.disabled = true;
    }

    $.ajax({
      data: {search: key , targets: JSON.parse(this.keyTarget.dataset.targets)},
      dataType:'json',
      type:'get',

      url: "/en/edit-autocomplete.json",
      success : function(data, textStatus, jqXHR) {
                  _this.setList(data);
                }
    });
  }

  setList(data) {
    let _this = this;
    var target = JSON.parse(this.keyTarget.dataset.targets)[0];
    var list = "";

    data[target].forEach(function (item, index) {
      list += _this.listItem(item, target)
    });
    
    this.menuTarget.querySelector('.mdc-list').innerHTML = list;
    if (list.length > 0) {
      this.menuTarget.classList.add('mdc-menu-surface--open');
    } else {
      this.menuTarget.classList.remove('mdc-menu-surface--open');
    }

  }

}
