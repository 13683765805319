import { Controller } from "stimulus";
import $ from 'jquery';
import { MDCDialog } from '@material/dialog';
import {initMagnificPopup} from '../packs/initMagnificPopup'

export default class extends Controller {
  static targets = ["editor"]

  connect () {
    let _this = this;

    this.initPostingTools();

    if (this.data.get("processing") == "true") {
      setTimeout(function() {_this.getPostBy(_this.data.get("postid"))}, 5000);
    }
  }

  onCreateSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.postProcessing(data);
  }

  postProcessing(data){
    let _this = this;

    if (data !== undefined) {
      if (data.status == "ok") {
        data.ids.forEach(function(item) {
          _this.getPostBy(item);
        });
      }

    } 

    let dialog = new MDCDialog(document.querySelector('.post-dialog'));

    dialog.open();
    dialog.close();

    setTimeout(function() {initMagnificPopup();}, 400);
  }


  onUpdateSuccess(event) {
    let post_id = this.data.get("postid");
    if (post_id > 0) {
      this.getPostBy(post_id);
      let dialog = new MDCDialog(document.querySelector('.post-dialog'));
      dialog.open();
      dialog.close();

      setTimeout(function() {initMagnificPopup();}, 400);
    }
  }

  onDismiss(event) {
    let post_id = this.data.get("postid");
    if (post_id > 0) {
      $("#post_" + post_id).removeClass("animate scale-up");
      $("#post_" + post_id).addClass("animate scale-down");
      setTimeout(function() {$("#post_" + post_id).remove();}, 400);
    }
  }

  getPostBy(post_id) {
    fetch("/posts/" + post_id + "?card=true")
    .then(data => {return data.text();})
    .then(html => {
      if (html.length > 0) {
        if ($("#post_" + post_id).length > 0) {
          $("#post_" + post_id).replaceWith(html);
        } else {
          $("#posts_grid").prepend(html);
        }
        
      }
    });
  }

  initPostingTools() {
    var regexp = /(\b(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/ig,
      root_host = window.location.protocol + "//" + window.location.host,
      current_link = null,
      prev_link = null,
      form_files = null,
      file_available = false,
      controller_url = null,
      body_iterval = null,
      controller_this = null;  

    let _this = this;

    $(".posting_tool #post_body").on('input', function () {
      showPreview();
    });

    $(".posting_tool .preview .clean").on("click", function (event) {
      cleanPostPreview();
    });

    $(".posting_tool #post_button").on("click", function (event) {
      if (file_available == true) { 

        var formData = new FormData();

        $(".posting_tool .posting.hidden").removeClass('hidden');
        $(this).hide();

        $.each($('.posting_tool form').serializeArray(), function(_, item) {
          formData.append(item.name, item.value);
        });

        if (form_files.length > 1) {

          for (var i=0; i < form_files.length; i++) {
            formData.append("post[photos][image"+i+"]", form_files[i]);
          }
        } else {
          formData.append("post[photo]", form_files[0]);
        }
        
        controller_url = "/posts";

        $.ajax({
          data: formData,
          type: "POST",
          processData: false,
          contentType: false,  
          url: root_host + controller_url,
          complete : function(data, textStatus, jqXHR) {
                      _this.postProcessing(data.responseJSON);
                    }
        });


      } else if ($(".posting_tool #post_body").val() != "" || $(".posting_tool #files").val() != "" || $(".posting_tool #pdf_files").val() != "" || $(".posting_tool #post_url").val() != "" ) { 
        $(".posting_tool form #submit_button").click();

        $(".posting_tool .posting.hidden").removeClass('hidden');
        $(this).hide();
      }
      form_files = null;
      file_available = false;
    });

    $(".posting_tool input#files").on("change", function (e) {
      beforeFileSelectHandler(e);
    });

    $(".posting_tool input#pdf_files").on("change", function (e) {
      beforePDFFileSelectHandler(e);
    });

    function cleanPostPreview() {
      $(".posting_tool #files, .posting_tool #pdf_files, .posting_tool #post_url").attr("value", "");
      $(".posting_tool #files").val('');
      $(".posting_tool #pdf_files").val('');
      file_available = false;
      prev_link = current_link;
      current_link = null;
      $(".posting_tool .previews").hide();
      $('.posting_tool .previews .preview .multi_images').html("");
      $(".posting_tool .preview img").attr("src", "").hide();
      $(".posting_tool .preview p").html("");
      $(".posting_tool .preview.link").removeClass('link');
    }

    function showPreview() {

      if (($(".posting_tool #post_body") !== undefined) && ($(".posting_tool #post_body").val() !== undefined)) {

        var text = $(".posting_tool #post_body").val();

        var link = text.match(regexp);
        if (link != null) {
          if (link[0] != current_link && link[0] != prev_link) {
            $(".posting_tool .previews").show();
            $(".posting_tool .loading_wrap").show();
            
            current_link = link[0];
            
            $.ajax({
              data: { 
                      link: link[0]
                    },
              dataType: 'json',
              type: 'get',
              url: root_host + "/post/preview",
              success : function(data, textStatus, jqXHR) {   
                          console.log(data);
                          
                          if ((data.type == "video") || (data.type == "photo") || (data.type == "rich")) {
                            $(".posting_tool .loading_wrap").hide();
                            //current_link = link[0];
                            $(".posting_tool #post_url").attr("value", current_link);
            
                            if (data.type == "video") $(".posting_tool .preview img").attr("src", data.thumbnail_url.replace("hqdefault", "maxresdefault")).show();
                            if (data.type == "photo") $(".posting_tool .preview img").attr("src", data.url).show();
                            if (data.type == "rich") {
                              $(".posting_tool .preview").addClass('link');
                              if (data.images !== []) {
                                $(".posting_tool .preview img").attr("src", data.thumbnail_url).show();
                              } else {
                                $(".posting_tool .preview img").attr("src", $(".posting_tool .link_bg").attr('src')).show();
                              }
                              //
                              $(".posting_tool .preview .link_title").html(data.title);
                              $(".posting_tool .preview .link_descr").html(data.description);
                            }
                          } else if (data.type == "pdf") {
                            $(".posting_tool .loading_wrap").hide();
                            //current_link = link[0];
                            $('.posting_tool .previews img').attr('src', $('.posting_tool .pdf_bg').attr('src')).show();

                            $(".posting_tool #post_url").attr("value", current_link);
                          } else if (data.status == "processing") {
                            //alert("processing");
                            current_link = null;
                            setTimeout(showPreview, 2000);
                          }
                        },
              complete: function () { },
              error: function (xhr, ajaxOptions, thrownError) {
                //alert("ERROR");
                current_link = null;
                setTimeout(showPreview, 3000);
                //maybe show 'refresh icon'
                //$(".posting_tool .loading_wrap").hide();
              }
            });
          }
        }
      }
    }

    // file drag hover
    function FileDragHover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.target.className = (e.type == "dragover" ? "hover" : "");
    }

    // file selection
    function FileSelectHandler(e) {
      // cancel event and hover styling
      FileDragHover(e);
      // fetch FileList object
      var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);
      form_files = e.target.files || (dt && dt.files);
      // process all File objects
      if (form_files && form_files[0]) {
        $('.posting_tool .previews').show();

        if (form_files.length > 1) {
          $(".posting_tool .preview").addClass('multi');
          let files_length = form_files.length
          
          if (files_length > 10)
            files_length = 10;

          for (var i=0; i < files_length; i++) {
            if ((form_files[i].size / 1024 / 1024) < 5){
              var reader = new FileReader();
              reader.onload = function (e) {
                $('.posting_tool .previews .preview .multi_images').append("<div class='img' style='background-image:url(" + e.target.result + ")'></div>");
              }
              reader.readAsDataURL(form_files[i]);
            }
          }
        } else {
          var reader = new FileReader();
          reader.onload = function (e) {
            $('.posting_tool .previews img').attr('src', e.target.result).show();
          }
          reader.readAsDataURL(form_files[0]);

        }
        file_available = true;
        $(".posting_tool .loading_wrap").hide();
        $('.posting_tool #post_body').focus();
      }
      var rotateImage = function(image) {
        switch(image.Orientation){
          case 8 || 7:
            $('.posting_tool .preview img').rotate(-90);
          break;
          case 3 || 4:
            $('.posting_tool .preview img').rotate(180);
          break;
          case 6 || 5:
            $('.posting_tool .preview img').rotate(90);
          break;
          default:
            $('.posting_tool .preview img').rotate(0);
        }
      };
      //$(".posting_tool input#files").fileExif(rotateImage);
    }

    function PDFFileSelectHandler(e) {
      FileDragHover(e);
      var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);
      form_files = e.target.files || (dt && dt.files);

      if (form_files && form_files[0]) {
        $('.posting_tool .previews').show();
        
        var reader = new FileReader();
        reader.onload = function (e) {
          $('.posting_tool .previews img').attr('src', $('.posting_tool .pdf_bg').attr('src')).show();
          //alert(form_files[0].name + "(" + toKb(form_files[0].size) + "Kb)");
        }
        reader.readAsDataURL(form_files[0]);

        $('.posting_tool #post_body').focus();
      }
    }

    function beforeFileSelectHandler(e) {
      var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);

      form_files = e.target.files || (dt && dt.files);

      if (typeof form_files !== 'undefined') {
        FileSelectHandler(e);
      } else {
        var file = $("#files").val();
        if ( ["JPEG","JPG","PNG","GIF"].indexOf(file.substr(file.lastIndexOf('.') +1).toUpperCase()) < 0) {
          alert("Please use an image file JPEG,JPG,PNG,GIF.");
          e.preventDefault();
        }
      }

    }

    function beforePDFFileSelectHandler(e) {

      var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);

      form_files = e.target.files || (dt && dt.files);

      if (typeof form_files !== 'undefined') {
        PDFFileSelectHandler(e); 
      } else {
        var file = $("#pdf_files").val();
        if ( ["PDF"].indexOf(file.substr(file.lastIndexOf('.') +1).toUpperCase()) < 0) {
          alert("Please use PDF files only.");
          e.preventDefault();
        }
      }

    }

  }

}