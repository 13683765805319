import { Controller } from "stimulus"
import {MDCChip} from '@material/chips';
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    let _this = this;

    document.addEventListener("MDCAutoInit:End", () => {_this.initializeForm(_this);});
  }

  initializeForm(_this) {
    const urlParams = new URLSearchParams(window.location.search);

    const chipElements = _this.element.querySelectorAll('.mdc-chip');
    let form = _this.element;

    const categories = urlParams.getAll("c[]");
    //for (const key of urlParams.keys()) console.log(key);

    chipElements.forEach((chipEl) => {   
      const chip = chipEl.MDCChip;

      categories.forEach((c) => {
        if (c.length != 0 && chipEl.dataset.category == c) {
          chip.selected = true;
          createInput(form, chipEl.dataset.category)
        }
      });

      chip.listen('MDCChip:interaction', () => {

        if (chip.selected) {
          _this.element.querySelector(`input[value='${chipEl.dataset.category}']`).remove();
        } else {

          if (chipEl.dataset.category.length == 0) {
            _this.element.querySelectorAll('input').forEach(e => e.remove());
            chipElements.forEach((_el) => {let _chip = _el.MDCChip; _chip.selected = (_el.dataset.category.length == 0);});
          } else {
            createInput(form, chipEl.dataset.category)
          }
        }

        chip.selected = !chip.selected;
      });  
    });
  }

}

function createInput(form, category) {
  let input = document.createElement("input");
  input.name = "c[]"
  input.setAttribute("value", category);
  input.classList.add("hidden");
  form.appendChild(input);
}