import { Controller } from "stimulus"
import $ from 'jquery';
import { DataSet, Timeline } from "vis-timeline/standalone";

//import "vis-timeline/styles/vis-timeline-graph2d.css";
var timeline = null;

export default class extends Controller {
  static targets = [ "chart", "visualization" ]


  connect () {
    let _this = this,
        filter = "";

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    filter = urlParams.get('f');

    fetch("/en/calendar.json?f=" + filter)
    .then(data => {return data.json();})
    .then(json => {
     
      // Create a DataSet (allows two way data-binding)
      var items = new DataSet(json.chart);

      // Configuration for the Timeline
      var options = {
        start: new Date(new Date().getFullYear(), 0, 1), 
        end: new Date(new Date().getFullYear(), 12, 31),
        max: new Date(new Date().getFullYear(), 12, 31),
        min: new Date(2017, 0, 1),
        zoomable: false,
        maxHeight: 800,
        minHeight: 200,
        zoomMin: 100000000,
        //cluster: true,
        interaction: {
          navigationButtons: true
        },
        xss: {
          disabled: true,
          // filterOptions: {
          //   whiteList: { p: ['class', 'data-from-template'], div: 'class' },
          // },
        },
      };
      ///var options = {};
      // Create a Timeline
      timeline = new Timeline(_this.visualizationTarget, items, options);

      timeline.on('select', function (properties) {
        //console.log('selected event: ' + properties.items);
        if (properties.items.length > 0) {
          $("#sessions").html("<div class='loader'></div>");
          fetch("/en/sessions?event_id=" + properties.items)
          .then(async response => {
            
            if (response.ok) {
              //console.log('selected sessions: ', html);
              $("#sessions").html(await response.text());

              $(".session-card .show_program").on("click", function () {
                var program = $(this).parent().parent().parent().find(".program");
                if (program.hasClass("active")) {
                  program.removeClass("active");
                } else {
                  program.addClass("active");
                  $('html,body').animate({ scrollTop: program.offset().top - 100}, 500, function () {
                  });
                }
              });
            } else
              $("#sessions").html("");
          });
        }
      });
    });
  }

  zoomIn(event) {
    timeline.zoomIn(0.3);
  }

  zoomOut(event) {
    timeline.zoomOut(0.3);
  }

}
