import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "select" ]

  connect () {
    //https://codepen.io/LoneFox/pen/OJVjPeg
    let _this = this;

    var selectOptions = _this.selectTarget.querySelectorAll('option');
    var selectParent = _this.selectTarget.parentElement;
    
    var autocomplete = false;

    if (_this.selectTarget.dataset.autocomplete == "true")
        autocomplete = true;
      
    var autocompleteInput = document.createElement('input');
    autocompleteInput.classList.add("mdc-text-field__input");
    autocompleteInput.placeholder = "Find...";

    var autocompleteMDCTextField = document.createElement('div');
    autocompleteMDCTextField.classList.add('mdc-text-field','mdc-text-field--filled','mdc-text-field--no-label', 'light-grey');

    autocompleteMDCTextField.appendChild(autocompleteInput);

    var newSelect = document.createElement('div');
    newSelect.classList.add('selectMultiple');

    if (autocomplete)
      newSelect.classList.add('with-autocomplete');

    if (_this.selectTarget.classList.contains('grey')) 
      newSelect.classList.add('grey');

    if (_this.selectTarget.classList.contains('medium')) 
      newSelect.classList.add('medium');
    
    var active = document.createElement('div');
    active.classList.add('active');
    var optionList = document.createElement('ul');
    var placeholder = _this.selectTarget.dataset.placeholder;

    var span = document.createElement('span');
    span.innerText = placeholder;
    active.appendChild(span);

    selectOptions.forEach((option) => {
      let text = option.innerText;
      if(option.selected){
        let tag = document.createElement('a');
            tag.dataset.value = option.value;
        tag.innerHTML = "<em>"+text+"</em><i></i>";
        active.appendChild(tag);
        span.classList.add('hidden2');
      }else{
        let item = document.createElement('li');
            item.dataset.value = option.value;
        item.innerHTML = text;
        optionList.appendChild(item);
      }
    });

    var arrow = document.createElement('div');
    arrow.classList.add('arrow');
    active.appendChild(arrow);

    newSelect.appendChild(active);
    newSelect.appendChild(optionList);

    if (autocomplete)
      newSelect.appendChild(autocompleteMDCTextField);

    _this.selectTarget.parentElement.append(newSelect);
    span.appendChild(_this.selectTarget);

    autocompleteInput.addEventListener('keyup', (e) => {
        optionList.querySelectorAll('li').forEach(function (element) {
            if (element.innerText.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
                element.style.display = "";
            else
                element.style.display = "none";
        });
    });

    window.addEventListener('click', (e) => {
        if (!selectParent.querySelector('.selectMultiple').contains(e.target)){
            selectParent.querySelector('.selectMultiple').classList.remove('open');

            if (autocomplete) {
              autocompleteInput.value = "";
            }
            
        }
    });

    selectParent.querySelector('ul').addEventListener('click', (e) => {
      let li = e.target.closest('li');
      if(!li){return;}
      let select = li.closest('.selectMultiple');
      let limit = 999;

      if (_this.selectTarget.dataset.limit > 0)
        limit = _this.selectTarget.dataset.limit

      if(!select.classList.contains('clicked') && (_this.selectTarget.selectedOptions.length < limit)){
        select.classList.add('clicked');
        if(li.previousElementSibling){
          li.previousElementSibling.classList.add('beforeRemove');
        }
        if(li.nextElementSibling){
          li.nextElementSibling.classList.add('afterRemove');
        }
        li.classList.add('remove');
        let a = document.createElement('a');
        a.dataset.value = li.dataset.value;
        a.innerHTML = "<em>"+li.innerText+"</em><i></i>";
        a.classList.add('notShown');
        // a.style.display = "none";
        select.querySelector('div').appendChild(a); //might have to check later
        let selectEl = select.querySelector('select');
        
        _this.selectTarget.querySelector('option[value="'+li.dataset.value+'"]').selected = true
        //FIX for change event. Without it 'change' is not fired after options were changed.
        _this.selectTarget.dispatchEvent(new Event('change'));

        if (_this.selectTarget.closest('form') != null)
          _this.selectTarget.closest('form').dispatchEvent(new Event('change'));

        //FIX FOR SAFARI, WITHOUT IT _this.selectTarget.checkValidity()); and _this.selectTarget.validity.valid); DIFFERENT (FALSE, TRUE)
        _this.selectTarget.setCustomValidity('');
        
        setTimeout(() => {
            a.classList.add('shown');
            select.querySelector('span').classList.add('hidden2');

        }, 100);
        //1st
        setTimeout(() => {
          let styles = window.getComputedStyle(li);
              let liHeight = styles.height;
              let liPadding = styles.padding;
              let removing = li.animate([
                  {
                      height: liHeight,
                      padding: liPadding
                  },
                  {
                      height: '0px',
                      padding: '0px'
                  }
              ], {
                  duration: 100, easing: 'ease-in-out'
              });
              removing.onfinish = () => {
                  if(li.previousElementSibling){
                      li.previousElementSibling.classList.remove('beforeRemove');
                  }
                  if(li.nextElementSibling){
                      li.nextElementSibling.classList.remove('afterRemove');
                  }
                  li.remove();
                  select.classList.remove('clicked');
                  if (autocomplete)
                      autocompleteInput.focus();
              }

        }, 100); //600
        //2nd
      }
    });
    //2
    //document.querySelectorAll('.selectMultiple > div a').forEach((a) => {
    selectParent.querySelector('div').addEventListener('click', (e) => {
        let a = e.target.closest('a');
        let select = e.target.closest('.selectMultiple');
        if(!a){return;}
        a.className = '';
        a.classList.add('remove');
        select.classList.add('open');
        let selectEl = select.querySelector('select');
        _this.selectTarget.querySelector('option[value="'+a.dataset.value+'"]').selected = false

        //FIX for change event. Without it 'change' is not fired after options were changed.
        _this.selectTarget.dispatchEvent(new Event('change'));

        //FIX FOR SAFARI, WITHOUT IT _this.selectTarget.checkValidity()); and _this.selectTarget.validity.valid); DIFFERENT (FALSE, TRUE)
        _this.selectTarget.setCustomValidity('');

        a.classList.add('disappear');
        setTimeout(() => {
            // start animation
            let styles = window.getComputedStyle(a);
            let padding = styles.padding;
            let deltaWidth = styles.width;
            let deltaHeight = styles.height;

            let removeOption = a.animate([
                {
                    width: deltaWidth,
                    height: deltaHeight,
                    padding: padding
                },
                {
                    width: '0px',
                    height: '0px',
                    padding: '0px'
                }
            ], {
                duration: 0,
                easing: 'ease-in-out'
            });
            
            let li = document.createElement('li');
            li.dataset.value = a.dataset.value;
            li.innerText = a.querySelector('em').innerText;
            li.classList.add('show');
            select.querySelector('ul').appendChild(li);
            setTimeout(() => {
                if(!selectEl.selectedOptions.length){
                    select.querySelector('span').classList.remove('hidden2');
                }
                li.className = '';
            }, 150);
            
            removeOption.onfinish = () => {
                a.remove();
                if (autocomplete)
                  autocompleteInput.focus();

            }
            //end animation

        }, 100);
    });
    //});
    //3
    selectParent.querySelectorAll('.active').forEach((el) => {
      el.addEventListener('click', (e) => {

        if (el.closest('.selectMultiple').classList.contains('open')) {
          el.closest('.selectMultiple').classList.remove('open');


        } else {
          el.closest('.selectMultiple').classList.add('open');
          
          if (autocomplete) {
            autocompleteInput.value = "";
            
            optionList.querySelectorAll('li').forEach(function (element) {
              element.style.display = "";
            });

            autocompleteInput.focus();
          }
        }


      });
    });

  }
}
