import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "grid"]

  getPagination(event) {
    let _this = this,
        url = "",
        ord = null,
        cnt = null,
        col = null,
        c = null,
        s = null,
        f = null,
        pg = 1;

    if (event) {
      url = event.currentTarget.dataset.url;
      ord = event.currentTarget.dataset.ord;
      cnt = event.currentTarget.dataset.cnt;
      col = event.currentTarget.dataset.col;
      c = event.currentTarget.dataset.c;
      s = event.currentTarget.dataset.s;
      f = event.currentTarget.dataset.f;
      pg = event.currentTarget.dataset.pg;
    }
    

    if (url.length > 0 && (pg < 2 || $("#pagination_button").hasClass("loading") == false)) {
      $("#pagination_button").addClass("loading");
      $.get( url, {
        ord: ord,
        cnt: cnt,
        col: col,
        c: c,
        s: s,
        f: f,
        pg: pg,
        pagination: true,
      })
      .done(function( data ) {
        if (data.length > 1) {

          let frag = document.createRange().createContextualFragment(data);
          _this.gridTarget.append(frag);

          $("#pagination_button").attr("data-pg", parseInt(pg) + 1);
        } else {
          $("#pagination_button").remove();
        }
        $("#pagination_button:not([autoload])").addClass("autoload");
        $("#pagination_button.loading").removeClass("loading");
      });
      
    }
  }

}
