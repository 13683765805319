import { Controller } from "stimulus"
import $ from 'jquery';
import {MDCSelect} from '@material/select';
import {initMagnificPopup} from '../packs/initMagnificPopup';
import {initSlickCarousel} from '../packs/initSlickCarousel';

export default class extends Controller {
  static targets = [ "output", "switch", "checkbox", "submit" ]

  connect () {
    let _this = this;

    document.addEventListener("MDCAutoInit:End", () => {initMagnificPopup();});
    document.addEventListener("MDCAutoInit:End", () => {initSlickCarousel();});

    _this.initFlatpickr();

    let swtch = null;

    if (_this.hasSwitchTarget)
      swtch = _this.switchTarget;

    if (swtch !== undefined && swtch != null) {

      let checkbox = swtch.querySelector("input[type=checkbox]");

      checkbox.addEventListener('change', (event) => {
        event.currentTarget.value = event.currentTarget.checked;
        
        if (event.currentTarget.checked) {
          swtch.classList.remove("status-off");
          swtch.classList.add("status-on");
        } else {
          swtch.classList.remove("status-on");
          swtch.classList.add("status-off");
        }
      })

    }

  }

  switchAmbassadorSubmit(event) {
    if ($("#service_request_receive_emails").prop('checked')) {
      $("#new_service_request input[type=submit]").prop("disabled", false);
    } else {
      $("#new_service_request input[type=submit]").prop("disabled", true);
    }
  }

  toggleSubmitByCheckbox(event) {
    console.log("checkbox: ", this.checkboxTarget.checked);

    if (this.checkboxTarget.checked == true) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  httpValidation(event) {
    var value;

    value = event.currentTarget.value;

    if (value && !value.match(/^http([s]?):\/\/.*/)) {
      event.currentTarget.value = 'https://' + value;
    }
  }

  initFlatpickr() {
    flatpickr('.flatpickr', {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      time_24hr: true, 
      allowInput: true
    });

    flatpickr('.flatpickr_date', {
      dateFormat: "Y-m-d",
      allowInput: true
    });
      
    flatpickr('.flatpickr_time', {
      enableTime: true,
      noCalendar: true,
      enableSeconds: true,
      dateFormat: "H:i:S",
      time_24hr: true,
      allowInput: true
    });
  }

}
