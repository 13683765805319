import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  closePopupAndReload() {
    $.magnificPopup.proto.close.call();
    location.reload();
  }

  closePopup() {
    $.magnificPopup.proto.close.call();
  }

}
