import { Controller } from "stimulus"
import $ from 'jquery';
import ENV from "packs/env.js.erb";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl';
import { countriesBounds } from "../packs/countries_bounds";

import { MDCDialog } from '@material/dialog';

//OLD
import { initBigMap4Innovation } from "../packs/map";
import { initBigMap } from "../packs/map";

import {MDCSelect} from '@material/select';

var countries_bounds = countriesBounds();

var map,
    communityValue = "",
    collectionValue = "",
    countryValue = "",
    implementationsValue = "",
    searchValue = "";

var bounds = [
    [-170,-57], // Southwest coordinates
    [190, 78] // Northeast coordinates
];

var searchTimer;

export default class extends Controller {
  static targets = ["map", "search"]

  static values = {url: String}

  initialize() {
    let _this = this;
    
    document.addEventListener("MDCAutoInit:End", () => {_this.initSelects();});
  }

  initSelects() {
    let _this = this;

    ['collection', 'community', 'country', 'implementations'].forEach(element => {
      let selectEl = document.querySelector("#" + element + "_filter");

      if (selectEl != null) {
        let select = selectEl.MDCSelect;

        if (element == "community"){
          communityValue = select.value;
        } else if (element == "collection") {
          collectionValue = select.value;
        } else if (element == "country") {
          countryValue = select.value;
        } else if (element == "implementations") {
          implementationsValue = select.value;
        }

        select.listen('MDCSelect:change', (event) => {
          _this.applyFilter(element, select.value, select);
        });
      }
    });

    this.getGrid("users", 1);
    this.getGrid("innovations", 1);

    $("#map-toggle").on("click", function() {
      if ($(this).hasClass('grey') == true) {
        $(this).removeClass('grey');
        $("#map-section").removeClass("hidden");

      } else if ($("#grid-toggle").hasClass('grey') == false) {
        $(this).addClass('grey');
        $("#map-section").addClass("hidden");
      }
    });

    $("#grid-toggle").on("click", function() {
      if ($(this).hasClass('grey') == true) {
        $(this).removeClass('grey');
        $("#innovations-section").removeClass("hidden");
        $("#users-section").removeClass("hidden");
      } else if ($("#map-toggle").hasClass('grey') == false) {
        $(this).addClass('grey');
        $("#innovations-section").addClass("hidden");
        $("#users-section").addClass("hidden");
      }

    });

	}

  getMapGrid() {
    this.mapDataRequest();
    this.getGrid("users", 1);
    this.getGrid("innovations", 1);
    this.getGrid("implementations", 1);
  }

  searchHandler() {
    var _this = this;

    if (_this.searchTarget.value.length > 2){
      searchValue = _this.searchTarget.value;

      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        _this.getMapGrid();
      }, 1000);
    } else {
      searchValue = "";

      if (_this.searchTarget.value.length == 0) {
        _this.getMapGrid();
      }

    }
  }

  resetSearch() {
    this.searchTarget.value = "";
    searchValue = "";
    this.getMapGrid();
  }

  getGrid(type, pg) {
    var cardAPI = "/en/map";
    let _this = this;

    if (type == "innovations") {
      $.get( cardAPI, {
        type: "innovations",
        collection: collectionValue,
        country: countryValue,
        s: searchValue,
        pg: pg,
        pagination: true
      })
      .done(function( data ) {
        if (data.length > 1) {

          if (pg <= 1) {
            $("#innovations-section .card-grid").html("");
            $("#innovations-section .card-grid.hidden").removeClass('hidden');
            $("#innovations-section .card-grid-footer.hidden").removeClass('hidden');
            $("#innovations-section .not-found:not(.hidden)").addClass('hidden');
          }

          $("#innovations-section .card-grid").append(data);
          $("#innovations-section .pagination-btn").attr("data-pg", parseInt(pg) + 1);
          $("#innovations-section .pagination-btn.hidden").removeClass("hidden");

        } else {

          if (pg <= 1) {
            $("#innovations-section .card-grid").addClass('hidden');
            $("#innovations-section .card-grid-footer").addClass('hidden');
            $("#innovations-section .not-found.hidden").removeClass('hidden');
          }

          $("#innovations-section .pagination-btn").addClass("hidden");

        }

        $("#innovations-section .pagination-btn.loading").removeClass("loading");
      });

    } else if (type == "users") {
      $.get( cardAPI, {
        type: "users",
        user_type: communityValue,
        collection: collectionValue,
        country: countryValue,
        s: searchValue,
        pg: pg,
        pagination: true,
      })
      .done(function( data ) {
        if (data.length > 1) {

          if (pg <= 1) {
            $("#users-section .card-grid").html("");
            $("#users-section .card-grid.hidden").removeClass('hidden');
            $("#users-section .card-grid-footer.hidden").removeClass('hidden');
            $("#users-section .not-found:not(.hidden)").addClass('hidden');
          }

          $("#users-section .card-grid").append(data);
          $("#users-section .pagination-btn").attr("data-pg", parseInt(pg) + 1);
          $("#users-section .pagination-btn.hidden").removeClass("hidden");

        } else {

          if (pg <= 1) {
            $("#users-section .card-grid").addClass('hidden');
            $("#users-section .card-grid-footer").addClass('hidden');
            $("#users-section .not-found.hidden").removeClass('hidden');
          }

          $("#users-section .pagination-btn").addClass("hidden");

        }

        $("#users-section .pagination-btn.loading").removeClass("loading");
      });
    } else if (type == "implementations") {
      $.get( cardAPI, {
        type: "implementations",
        user_type: communityValue,
        collection: collectionValue,
        country: countryValue,
        s: searchValue,
        pg: pg,
        pagination: true,
      })
      .done(function( data ) {
        if (data.length > 1) {

          if (pg <= 1) {
            $("#implementations-section .card-grid").html("");
            $("#implementations-section .card-grid.hidden").removeClass('hidden');
            $("#implementations-section .card-grid-footer.hidden").removeClass('hidden');
            $("#implementations-section .not-found:not(.hidden)").addClass('hidden');
          }

          $("#implementations-section .card-grid").append(data);
          $("#implementations-section .pagination-btn").attr("data-pg", parseInt(pg) + 1);
          $("#implementations-section .pagination-btn.hidden").removeClass("hidden");

        } else {

          if (pg <= 1) {
            $("#implementations-section .card-grid").addClass('hidden');
            $("#implementations-section .card-grid-footer").addClass('hidden');
            $("#implementations-section .not-found.hidden").removeClass('hidden');
          }

          $("#implementations-section .pagination-btn").addClass("hidden");

        }

        $("#implementations-section .pagination-btn.loading").removeClass("loading");
      });
    }
      
  }

  getGridPagination(event) {
    let _this = this;
    let btn = event.currentTarget;
    let pg = parseInt(event.currentTarget.dataset.pg);
    let type = event.currentTarget.dataset.type;

    if (btn.classList.contains("loading") == false) {
      btn.classList.add("loading");
      _this.getGrid(type, pg);
    }
  }

  initCollectionMap() {
    let projection = "equirectangular";
    let groupingLevel = 4.5;
    let country = null;

    if (this.mapTarget.dataset.country !== undefined && this.mapTarget.dataset.country.length > 0 && this.mapTarget.dataset.country != 'global') {
      groupingLevel = 0.5;
      projection = "mercator";
      country = this.mapTarget.dataset.country;
    }
    
    initBigMap(country, true, true, projection, true, groupingLevel, JSON.parse(this.mapTarget.dataset.locations))
  }

  initInnovationMap() {
    let regions = this.mapTarget.dataset.regions
    initBigMap4Innovation(JSON.parse(regions));
  }

  applyFilter(type, value, selectObject) {
    let _this = this;

    console.log(type, value,selectObject);

    if (type.length > 0) {

      if (value == 'empty')
        value = "";

      if (type == "community"){
        communityValue = value;

      } else if (type == "collection") {
        collectionValue = value;

      } else if (type == "implementations") {
        implementationsValue = value;

      } else if (type == "country") {
        countryValue = value;

        if (countryValue.length > 0)
          _this.focusCountry(countryValue);
        else {
          map.setFilter('country-boundaries', false);
          map.fitBounds(bounds, {
            padding: 20
          });
        }
      }

      this.getMapGrid();
    }
  }

  mapDataRequest() {
    fetch("/en/new-map?type=" + communityValue + "&country=" + countryValue + "&collection=" + collectionValue + "&s=" + searchValue+ "&implementations=" + implementationsValue)
    .then(response => response.json())
    .then(data => map.getSource('objects').setData(data));
  }

  resetMap(event) {
    var select1 = document.querySelector("#collection_filter").MDCSelect;
    select1.selectedIndex = 0;

    var select2 = document.querySelector("#community_filter").MDCSelect;
    select2.selectedIndex = 0;

    var select3 = document.querySelector("#country_filter").MDCSelect;
    select3.selectedIndex = 0;

    var select4 = document.querySelector("#implementations_filter").MDCSelect;
    select4.selectedIndex = 1;

    map.setFilter('country-boundaries', false);
    map.fitBounds(bounds, {
      padding: 20
    });

    communityValue = "";
    collectionValue = "";
    countryValue = "";
    implementationsValue = "";
    this.resetSearch();
  }

  initMainMapWithDelay(event) {
    let _this = this;

    setTimeout( function() { _this.initMainMap(event); }, 300); 
  }

  initCommunityLeadsMapWithDelay(event) {
    let _this = this;
    communityValue = "lead";
    collectionValue = "no";

    setTimeout( function() { _this.initMainMap(event); }, 300); 
  }

  initMainMap(event) {
    let _this = this;
    let mapstyle = 'mapbox://styles/hundred-org/ckmopzq5j4ax817ly3iea0sfu?refresh=true';

    if (this.mapTarget.dataset.mapstyle !== undefined) {
      console.log(this.mapTarget.dataset.mapstyle);
      mapstyle = 'mapbox://styles/mapbox/' + this.mapTarget.dataset.mapstyle;
    }

    mapboxgl.accessToken = ENV.mapboxKey();
    map = new mapboxgl.Map({
      container: 'map',
      style: mapstyle,
      //center: [10.400000, 43.716667],
      zoom: -1,
      maxZoom: 9,
      maxBounds: bounds,
      attributionControl: false
    });

    map.addControl(new mapboxgl.AttributionControl({
      compact: true
    }));

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());
    
    map.on('load', function() {
      $(".overlay").fadeOut( "slow");

      map.addSource('objects', {
        type: 'geojson',
        data: "/en/new-map?type=" + communityValue + "&country=" + countryValue + "&collection=" + collectionValue + "&s=" + searchValue,
        cluster: true,
        clusterMaxZoom: 10, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
        // keep separate counts for each category in a cluster
          has_innovation: ["any", ["==", ["get", "entity"], 'Innovation'], "false"],
          has_user: ["any", ["==", ["get", "entity"], 'User'], "false"],
          has_implementation: ["any", ["==", ["get", "entity"], 'Implementation'], "false"],
          only_innovation: ["all", ["==", ["get", "entity"], 'Innovation'], "false"],
          only_user: ["all", ["==", ["get", "entity"], 'User'], "false"],
          only_implementation: ["all", ["==", ["get", "entity"], 'Implementation'], "false"]
        }
      });

      map.addLayer(
        {
          id: 'country-boundaries',
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#000',
            'fill-opacity': 0.1,
          },
        }
      );

      map.setFilter('country-boundaries', false);

      Promise.all(
          [
            {url: '/cluster-black.png', id: "black"}, 
            {url: '/cluster-plum.png', id: "plum"},
            {url: '/cluster-blue.png', id: "blue"},
            {url: '/cluster-black-plum.png', id: "plum_black"}
          ].map(img => new Promise((resolve, reject) => {
              map.loadImage(img.url, function (error, res) {
                  map.addImage(img.id, res)
                  resolve();
              })
          }))
      )
      .then(data => {
        map.addLayer({
          id: 'clusters',
          type: 'symbol',
          source: 'objects',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16,

            'icon-image': [
              "case",
              ["all", ["get", "has_innovation"], ["get", "has_user"]], 
              "plum_black",
              ["get", "only_user"],
              "black",
              ["get", "only_implementation"],
              "blue",
              "plum"
            ], // reference the image
            'icon-size': 0.6,
          },           
          paint: {
            "text-color": "#fff"
          }
        });

      });
       
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'objects',
        filter: ['!', ['has', 'point_count']],
        paint: {
          //'circle-color': ["rgb",  ["get", "entity"], '#c32fa0'],
          'circle-color': [
            'match',
            ['get', 'entity'],
            'User',
            '#000000',
            'Implementation',
            '#117C96',
            /* other */ '#c32fa0'
          ],
          'circle-radius': 10,
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#fff'
        }
      });

      map.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'objects',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          "text-color": "#fff"
        }
      });

      map.on('click', 'clusters', function (e) {
        var features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        
        var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        var clusterId = features[0].properties.cluster_id,
        point_count = features[0].properties.point_count,
        clusterSource = map.getSource('objects');

        if (map.getZoom() > 6.5) {
          clusterSource.getClusterLeaves(clusterId, point_count, 0, function(err, aFeatures){
            _this.initDialog(e,  aFeatures, map);
          });
        } else {


          map.getSource('objects').getClusterExpansionZoom(
            clusterId,
            function (err, zoom) {
              if (err) return;
               
              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            }
          );
        }

      });

      map.on('click', 'unclustered-point', function (e) {
        _this.initDialog(e,  e.features, map);
      });

      map.on('mouseenter', 'clusters', function () {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'clusters', function () {
        map.getCanvas().style.cursor = '';
      });

      map.on('mouseenter', 'unclustered-point', function () {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'unclustered-point', function () {
        map.getCanvas().style.cursor = '';
      });

    });
  }

  initImplementationMap(event) {
    let _this = this;

    mapboxgl.accessToken = ENV.mapboxKey();
    map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/hundred-org/ckmopzq5j4ax817ly3iea0sfu?refresh=true',
      zoom: -1,
      maxZoom: 10,
      maxBounds: bounds,
      attributionControl: false
    });

    map.addControl(new mapboxgl.AttributionControl({
      compact: true
    }));

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());
    
    map.on('load', function() {
      $(".overlay").fadeOut( "slow");
      map.addSource('objects', {
        type: 'geojson',
        data: {"type": "FeatureCollection","features": []}
      });

      fetch('/en/addresses?implementation_id=' + _this.mapTarget.dataset.objectid)
      .then(response => response.json())
      .then((data) => {
        map.getSource('objects').setData(data);

        var bounds = new mapboxgl.LngLatBounds();

        data.features.forEach(function(feature) {
            bounds.extend(feature.geometry.coordinates);
        });

        map.fitBounds(bounds, { padding: 100 });
      });

      map.addLayer(
        {
          id: 'country-boundaries',
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#000',
            'fill-opacity': 0.1,
          },
        }
      );

      map.setFilter('country-boundaries', false);

      Promise.all(
          [
            {url: '/cluster-black.png', id: "black"}, 
            {url: '/cluster-plum.png', id: "plum"},
            {url: '/cluster-black-plum.png', id: "plum_black"}
          ].map(img => new Promise((resolve, reject) => {
              map.loadImage(img.url, function (error, res) {
                  map.addImage(img.id, res)
                  resolve();
              })
          }))
      )
      .then(data => {
        map.addLayer({
          id: 'clusters',
          type: 'symbol',
          source: 'objects',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16,

            'icon-image': [
              "case",
              ["all", ["get", "has_innovation"], ["get", "has_user"]], 
              "plum_black",
              ["get", "only_user"],
              "black",
              ["get", "only_implementation"],
              "blue",
              "plum"
            ], // reference the image
            'icon-size': 0.6,
          },           
          paint: {
            "text-color": "#fff"
          }
        });

      });
       
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'objects',
        filter: ['!', ['has', 'point_count']],
        paint: {
          //'circle-color': ["rgb",  ["get", "entity"], '#c32fa0'],
          'circle-color': [
            'match',
            ['get', 'entity'],
            'User',
            '#000000',
            'Implementation',
            '#117C96',
            /* other */ '#c32fa0'
          ],
          'circle-radius': 10,
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#fff'
        }
      });

      map.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'objects',
        filter: ['!', ['has', 'point_count']],
        layout: {
          //'text-field': ['get', 'amount'],

          //'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          "text-color": "#fff"
        }
      });
    });

  }

  initDialog(event, features, map) {
    let dialog = null;

    let dialogTemplate = document.querySelector("#map-dialog-template");
    let dialogEl = null;

    const div = document.createElement('div');
    div.innerHTML = dialogTemplate.innerHTML;
    dialogEl = div.firstElementChild;
    dialogEl.classList.add("current-map-dialog");
    document.body.appendChild(dialogEl);


    let elements = [...new Set(features.map(x => x.properties.id))];
    var ids = "";

    elements.forEach(function(element) {
      ids += "ids[]=" + element + "&"
    })


    dialog = new MDCDialog(dialogEl);

    dialog.open();

    $.getScript("/en/new-map.js?" + ids);

    dialog.listen('MDCDialog:closed', function() {
      dialog.root.remove();
    });
   
  }

  focusCountry(countryAlpha3) {

    if (map !== undefined && map != null) {
      if (countryAlpha3.length > 0){
      
        map.setFilter('country-boundaries', [
          "in",
          "iso_3166_1_alpha_3",
          countryAlpha3
        ]);
      
        var coords = countries_bounds[countryAlpha3];
        map.fitBounds([[coords.sw.lon, coords.sw.lat],[coords.ne.lon, coords.ne.lat]], {
          padding: 20
        });
      } else {
        map.setFilter('country-boundaries', false);
      }
    }
  }

}


