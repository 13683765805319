import "slick-carousel";
import $ from 'jquery'

export function initSlickCarousel() {

  if ($('.slick-carousel-posts') != undefined || $('.slick-carousel') != undefined || $('.slick-carousel-media') != undefined) {
    
    $('.slick-carousel-posts').slick({
      dots: true,
      adaptiveHeight: false,
      arrows: true,
    });

    $(".slick-carousel").slick({
      dots: true,
      adaptiveHeight: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [{
        breakpoint: 660,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });

    $('.slick-carousel-media').slick({
      dots: true,
      adaptiveHeight: false,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [{
        breakpoint: 660,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
        },{
        breakpoint: 1020,
        settings: {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }]
    });

  }
}