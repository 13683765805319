import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {

  switchIntro(event) {
    setTimeout(function () { 
      if ($("#intro-switch").attr("aria-checked") == "true") {
        $(".intro .old_intro").addClass("hidden");
        $(".intro .new_intro").removeClass("hidden");
        $("#dialog-submit-button").prop("disabled", true);
      } else {
        $(".intro .old_intro").removeClass("hidden");
        $(".intro .new_intro").addClass("hidden");
        $("#dialog-submit-button").prop("disabled", false);
      }
    }, 200);
  }

}
