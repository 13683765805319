import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "fileField", "imagePreview", "output", "button", "input", "imagePreview"]

  initialize () {
    let _this = this;
    let fileField = _this.fileFieldTarget;
    let imagePreview = _this.imagePreviewTarget;

    fileField.onchange = evt => {
      let fileUrl;

      fileUrl = URL.createObjectURL(evt.target.files[0]);

      console.log("type =", imagePreview.dataset.type);
      if (fileUrl != null && fileUrl != undefined) {
        if (imagePreview.dataset.type == "image") {
          imagePreview.src = fileUrl;
          imagePreview.onload = function() {
            URL.revokeObjectURL(imagePreview.src) // free memory
          }

        } else if (imagePreview.dataset.type == "background") {
          imagePreview.style.backgroundImage = "url('" + fileUrl + "')";
          imagePreview.innerHTML = "";
        }

      }

    }

  }

  openUploader(event) {
    this.inputTarget.click();
  }

  getFileInfo(event) {
    var file = event.currentTarget.files[0];
    var name = file.name

    if (name.length > 30) name = name.substring(0, 27) + "... ";
    this.outputTarget.innerHTML = name + " (" + this.toKb(file.size) + "Kb)";
  }

  toKb(num) {
    var result = num/1024;
    result = (Math.round(result * 100) / 100)
    return result;
  } 

  getPreview(event) {
    let imagePreview = this.imagePreviewTarget;
    let fileUrl;

    fileUrl = URL.createObjectURL(event.currentTarget.files[0]);
    if (fileUrl != null && fileUrl != undefined) {
      imagePreview.style.backgroundImage = "url('" + fileUrl + "')";
      imagePreview.innerHTML = "";
    }

  }

} 
