import Rails from '@rails/ujs';

export default function logs() {

  setTimeout(function(){
    var empty = ($('#search-results').children().length > 0) ? false : true;
    var txt = $("#search_key").val();
    // console.log(">>> logs <<<<");
    // console.log(">>> "+$('#search-results').children().length+" <<<<");
    $.ajax({
      url: "/en/search_log",
      type: 'post',
      data: {text: txt, empty: empty},
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });
  }, 1000);

}